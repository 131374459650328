.alphabet_table
    font-size: 1.5em
    font-family: "FreeSerif", serif

.note
    font-size: medium

@font-face
    font-family: FreeSerif
    src: url("fonts/FreeSerif.woff")

#mainTextarea
    resize: none
    border-radius: 15px
    padding: 20px
    margin-top: 10px
    margin-bottom: 20px
    background-color: white
    color: black
    font-family: "FreeSerif", serif
    font-size: 3vw
    width: 100%

.btn
    margin: 5px

footer
    padding-bottom: 0
    font-style: italic
    color: gray

$body-bg: #f8f9fa
$body-color: #343a40
$enable-shadows: true
$accordion-button-bg: #f0f0f0
$accordion-button-active-bg: #e9e9e9
$accordion-button-active-color: $body-color
$accordion-bg: lightgray
//Padding
$container-padding-x: 3.5rem
@import "~bootstrap/scss/bootstrap"
